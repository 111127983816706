import React, { Component } from 'react'
import intl from 'services/intl'
import utils from 'services/utils'
import { DATE_FORMAT_REGEX } from 'services/constants'

class Form extends Component {
  constructor(props) {
    super(props)

    this.form = React.createRef()
  }

  render() {
    const { children, className } = this.props

    return (
      <form ref={this.form} className={className} onSubmit={this.submit}>
        {children}
      </form>
    )
  }

  submit = e => {
    e.preventDefault()

    const { onSubmit, onError, forceValidation } = this.props

    const isValid = this.validation()

    if (!isValid && onError) {
      onError()
    }

    if (isValid || forceValidation) {
      const next = onSubmit(this.getFields()) || Promise.resolve()
      next.catch(errors => {
        if (errors) {
          this.postError(errors)
        }
      })
    }
  }

  reset = () => {
    this.form.current.reset()
  }

  getFields = () => {
    return Array.from(this.form.current.elements).filter(e => e.getAttribute('name'))
  }

  validation = () => {
    const elements = this.getFields()
    const time = performance.now()

    let isValid = true

    elements.forEach(element => {
      let parent, error

      if (element.dataset.hasOwnProperty('required')) {
        parent = utils.closest(element, '.fg-field')
        error = parent.querySelector('.fg-fieldError')

        // To prevent the removing of the  error text for several inputs into the same field
        if (error.time !== time) {
          error.time = time
          error.textContent = ''
        }

        if (JSON.parse(element.dataset.required || null)) {
          if (element.dataset.requiredType === 'email') {
            if (
              !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                element.value
              )
            ) {
              isValid = false
              error.textContent = intl`Veullez saisir une adresse email valide`
            }
          } else if (element.dataset.requiredType === 'date') {
            if (!DATE_FORMAT_REGEX.test(element.value)) {
              isValid = false
              error.textContent = intl`Veuillez saisir une date valide.`
            }
          } else if (element.dataset.requiredType === 'phone') {
            if (!/^(0[1-7|9])(\d{8})$/.test(element.value)) {
              isValid = false
              error.textContent = intl`Le numéro de téléphone est invalide.`
            }
          } else if (element.dataset.requiredType === 'mobile-phone') {
            if (!/^(06|07)(\d{8})$/.test(element.value)) {
              isValid = false
              error.textContent = intl`Le numéro de téléphone portable est invalide.`
            }
          } else if (element.dataset.requiredType === 'home-phone') {
            if (!/^(0[1-5])(\d{8})$/.test(element.value)) {
              isValid = false
              error.textContent = intl`Le numéro de téléphone domicile est invalide.`
            }
          } else if (
            element.dataset.requiredType === 'checkbox' &&
            !document.querySelector(`input[name=${element.name}]:checked`) &&
            !error.textContent
          ) {
            isValid = false
            error.textContent = intl`Champ obligatoire`
          } else if (element.dataset.requiredType === 'password') {
            const fields = document.querySelectorAll(`input[name=${element.name}]`)

            if (fields.length === 1) {
              if (!/^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{10,}$/.test(element.value)) {
                isValid = false
                error.textContent = intl`Veuillez confirmer votre nouveau mot de passe`
              }
            } else if (fields[1].isSameNode(element) && fields[0].value !== element.value) {
              isValid = false
              error.textContent = intl`Les mots de passe ne sont pas identiques`
            }
          } else if (element.dataset.requiredType === 'file') {
            if (!element.files.length) {
              isValid = false
              error.textContent = intl`Document obligatoire`
            }
          }

          const invalidSelect =
            element.nodeName === 'SELECT' && !element.selectedOptions[0].getAttribute('value')

          const invalidRadio =
            element.type === 'radio' &&
            !Array.from(this.form.current.querySelectorAll(`[name=${element.name}]`)).filter(
              input => input.checked
            ).length

          if (invalidSelect || invalidRadio || !element.value.trim()) {
            isValid = false
            error.textContent = intl`Champ obligatoire`
          }

          if (error.textContent) {
            error.style.display = 'block'
          } else {
            error.style.display = 'none'
          }
        }
      }
    })

    return isValid
  }

  postError = (errors = {}) => {
    this.resetError()
    Object.keys(errors).forEach(name => {
      const element = this.form.current.querySelector(`[name=${name}]`)

      if (element) {
        element.nextElementSibling.textContent = intl(errors[name])
        element.nextElementSibling.style.display = 'block'
      }
    })
  }

  resetError = () => {
    const elements = this.getFields()

    elements.forEach(element => {
      if (element.dataset.required) {
        const parent = utils.closest(element, '.fg-field')
        const error = parent.querySelector('.fg-fieldError')

        error.textContent = ''
        error.style.display = 'none'
      }
    })
  }
}

export default React.forwardRef((props, ref) => <Form ref={ref} {...props} />)
