import React, { useState } from 'react'
import styled, { getFont } from 'services/style'

const MainCSS = styled.select`
  display: block;
  padding: 0 40px 0 10px;
  width: 100%;
  height: 29px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  color: ${({ isSelected }) => (isSelected ? '#454545' : '#b1b1b1')};
  ${getFont('OS', 400, 13)};
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGZpbGw9IiMwMDAwMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgMzIgMzIiPiA8cGF0aCBkPSJNMC41LDcuNmMtMC43LDAuNy0wLjcsMS45LDAsMi42bDE0LjIsMTQuMmMwLjQsMC4zLDAuOCwwLjUsMS4zLDAuNWMwLjUsMCwwLjktMC4xLDEuMy0wLjVsMTQuMS0xNC4zYzAuNy0wLjcsMC43LTEuOCwwLTIuNSBjLTAuNy0wLjctMS44LTAuNy0yLjUsMEwxNiwyMC42TDMsNy42QzIuMyw2LjksMS4yLDYuOSwwLjUsNy42TDAuNSw3LjZ6Ii8+IDwvc3ZnPg==');
  background-position: right 15px center;
  background-repeat: no-repeat;
  background-size: 10px;
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &[disabled] {
    opacity: 0.5;
  }
`

export default React.forwardRef(({ children, ...props }, ref) => {
  const [isSelected, setInputState] = useState(Boolean(props.defaultValue))
  const handleChange = event => {
    if (event.target.value) {
      setInputState(true)
    } else {
      setInputState(false)
    }

    if (props.onChange) {
      props.onChange(event)
    }
  }

  return (
    <MainCSS ref={ref} {...props} isSelected={isSelected} onChange={handleChange}>
      {children}
    </MainCSS>
  )
})
